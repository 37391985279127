export const metricsHero = {
    id: 'contactHero',
    lightBg: false,
    lightText: false,
    lightTextDesc: true,
    topLine: '',
    headline: 'Relocation Metrics',
    description: "Moving to a new home is considered one of the most stressful life events, as there are many things to manage such as packing, finding a new place, and adjusting to a new community. However, with proper planning and support, the stress of moving can be minimized. ",
    btnLabel: '',
    imgStart: false,
    divStart: false,
    shadow: true,
    onClk: true,
    img: 'https://relocate-storage.s3.us-east-2.amazonaws.com/relocate-images/MoveMetrics/metricsHero.svg',
    bg: '',
    alt: 'Services',
    tooo: '',
    dark: false,
    primary: true,
    darkText: true,
    topLineS: false

};
export const stat1 = {
    id: 'metrics',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: '',
    headline: 'General Statistics',
    description: "In 2022, an estimated 9.3% of Americans moved. This represents a slight increase from the 8.4% who moved in 2021, but is still lower than the 40 million people who moved annually in the previous five years",
    description2: "The average distance of a move in the United States is around 1,200 miles, with the majority of moves occurring within the same state",
    description3: "The busiest moving season in the United States is typically during the summer months, with the most popular moving day being July 1st",
    buttonLabel: '',
    imgStart: true,
    shadow: true,
    img: 'https://relocate-storage.s3.us-east-2.amazonaws.com/relocate-images/MoveMetrics/moveMetric1.svg',
    bg: require('./MetricsImg/metricsDivBg1.svg'),
    dark: false,
    primary: true,
    darkText: true,
    topLineS: false

};

export const stat2 = {
    id: 'metrics',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: '',
    headline: 'Why are Americans Moving?',
    description: "Climate change and natural disasters have become increasingly significant factors in motivating Americans to relocate. In 2022, one in ten homes in the US, or 14.5 million households, were affected by natural catastrophes",
    description2: "The COVID-19 pandemic also remains a leading cause of migration within the United States",
    description3: "Job opportunities are a significant factor in motivating Americans to move, with 25.6% of those who moved in 2022 doing so for work-related reasons",
    buttonLabel: '',
    imgStart: false,
    shadow: true,
    img: 'https://relocate-storage.s3.us-east-2.amazonaws.com/relocate-images/MoveMetrics/moveMetric2.svg',
    bg: require('./MetricsImg/metricsDivBg2.svg'),
    dark: false,
    primary: true,
    darkText: true,
    topLineS: false

};

export const stat3 = {
    id: 'metrics',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: '',
    headline: 'Average Cost of Moving',
    description: "On average, full service movers can cost anywhere from $400 to $15,200 and up, depending on the size of your home, the distance you’ll travel, and the method of moving",
    description2: "Large houses, long distances, and heavy or fragile belongings can also increase the cost of using full service movers",
    description3: "Professional local movers typically cost between $278 to $8,000 and up, depending on the size of your move, how long they’ll be working, and how many rooms need to be moved",
    buttonLabel: '',
    imgStart: true,
    shadow: true,
    img: 'https://relocate-storage.s3.us-east-2.amazonaws.com/relocate-images/MoveMetrics/moveMetric3.svg',
    bg: require('./MetricsImg/metricsDivBg1.svg'),
    dark: false,
    primary: true,
    darkText: true,
    topLineS: false

};

export const stat4 = {
    id: 'metrics',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: '',
    headline: 'Who is Moving?',
    description: "According to recent data, Black and Hispanic Americans are more likely to move than White Americans",
    description2: "Black Americans are more likely to move for job-related reasons, while Hispanic Americans are more likely to move for family-related reasons",
    description3: "Asian Americans are the least likely to move, with only 3.3% of Asian American households reporting a move in 2022",
    buttonLabel: '',
    imgStart: false,
    shadow: true,
    img: 'https://relocate-storage.s3.us-east-2.amazonaws.com/relocate-images/MoveMetrics/moveMetric4.svg',
    bg: require('./MetricsImg/metricsDivBg2.svg'),
    dark: false,
    primary: true,
    darkText: true,
    topLineS: false

};

export const stat5 = {
    id: 'metrics',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: '',
    headline: 'Environmental Impact of Moving',
    description: "Moving has a significant environmental impact due to the transportation of goods and the carbon emissions associated with the process",
    description2: "To reduce the environmental impact of moving, Americans can opt for eco-friendly packing materials, donate or sell unwanted items, and choose a moving company with a focus on sustainability",
    description3: "Additionally, reducing the frequency of moves and choosing a home closer to work or public transportation can help reduce carbon emissions",
    buttonLabel: '',
    imgStart: true,
    shadow: true,
    img: 'https://relocate-storage.s3.us-east-2.amazonaws.com/relocate-images/MoveMetrics/moveMetric5.svg',
    bg: require('./MetricsImg/metricsDivBg1.svg'),
    dark: false,
    primary: true,
    darkText: true,
    topLineS: false

};



export const facts = {
    id: 'metrics',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    headline: 'DISTANCE',
    headline2: 'SIZE OF THE MOVE',
    headline3: 'PACKING AND PREPARATION',
    headline4: 'WEATHER',
    headline5: 'ADDITIONAL SERVICES',
    headline6: 'TRAFFIC',
    headline7: 'ACCESS',
    headline8: 'MOVING CREW',
    headline9: 'EQUIPMENT',
    headline10: 'UNFORSEEN CIRCUMSTANCES',
    description: "The farther the distance of the move, the longer it will take to complete the move",
    description2: "The size of the move, including the number of items and the size of the furniture, can impact how long it takes to complete",
    description3: "Proper packing and preparation take time and can delay the moving process",
    description4: "Extreme weather conditions can cause delays and complications during a move",
    description5: "Additional services like packing, unpacking, and storage can add time to the moving process",
    description6: "Traffic congestion can cause significant delays during a move, especially in urban areas",
    description7: "The efficiency and experience of the moving crew can impact the speed of the moving process",
    description8: "Limited access to a property, such as narrow driveways, stairs, or elevators, can add time to a move",
    description9: "The availability and quality of equipment, such as moving trucks, dollies, and furniture pads, can affect the moving process",
    description10: "Unexpected problems like damaged items or difficult-to-move furniture can extend the moving process",
    logo: require("./MetricsImg/moveStatA1.svg"),
    logo2: require("./MetricsImg/moveStatA2.svg"),
    logo3: require("./MetricsImg/moveStatA3.svg"),
    logo4: require("./MetricsImg/moveStatA4.svg"),
    logo5: require("./MetricsImg/moveStatA5.svg"),
    logo6: require("./MetricsImg/moveStatA6.svg"),
    logo7: require("./MetricsImg/moveStatA7.svg"),
    logo8: require("./MetricsImg/moveStatA8.svg"),
    logo9: require("./MetricsImg/moveStatA9.svg"),
    logo10: require("./MetricsImg/moveStatA10.svg"),
    containerBg: "./MetricsImg/factContainerBg1.svg",
    imgStart: false,
    shadow: true,
    img: '',
    bg: '',
    dark: false,
    primary: true,
    darkText: true,
    topLineS: false

};

export const facts2 = {
    id: 'metrics',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: '',
    headline: 'JOB OPPORTUNITIES',
    headline2: 'HOUSING COST',
    headline3: 'FAMILY',
    headline4: 'EDUCATION',
    headline5: 'CLIMATE AND WEATHER',
    headline6: 'LIFESTYLE AND AMENITIES',
    headline7: 'HEALTH CONCERNS',
    headline8: 'RELATIONSHIPS',
    headline9: 'ADVENTURE AND EXPLORATION',
    headline10: 'RETIREMENT',
    description: "People move to find better job opportunities or to be closer to their workplace",
    description2: "Some individuals move to find more affordable housing",
    description3: "People move to be closer to family members or to take care of family",
    description4: "People may relocate for educational purposes or to be in a better school district",
    description5: "Individuals may move to escape extreme weather conditions or find a more desirable climate",
    description6: "Some may move for access to cultural activities, outdoor recreation, or other amenities",
    description7: "People move to be closer to medical facilities or to improve their overall health",
    description8: "Individuals may move to be closer to a significant other or for other personal relationships",
    description9: "Some people move to explore new places and cultures, or for the thrill of starting anew",
    description10: "Many individuals move upon retirement to find a new place to settle down and enjoy their golden years",
    logo: require("./MetricsImg/moveStatB1.svg"),
    logo2: require("./MetricsImg/moveStatB2.svg"),
    logo3: require("./MetricsImg/moveStatB3.svg"),
    logo4: require("./MetricsImg/moveStatB4.svg"),
    logo5: require("./MetricsImg/moveStatB5.svg"),
    logo6: require("./MetricsImg/moveStatB6.svg"),
    logo7: require("./MetricsImg/moveStatB7.svg"),
    logo8: require("./MetricsImg/moveStatB8.svg"),
    logo9: require("./MetricsImg/moveStatB9.svg"),
    logo10: require("./MetricsImg/moveStatB10.svg"),
    containerBg: "./MetricsImg/factContainerBg2.svg",
    imgStart: false,
    shadow: true,
    img: '',
    bg: '',
    dark: false,
    primary: true,
    darkText: true,
    topLineS: false

};

