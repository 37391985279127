export const Data = [
    {
      question: 'Question 1',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquam elit quis velit fermentum, eget tristique tortor hendrerit. Suspendisse sed aliquet sapien. Sed finibus consectetur ex, et tempor nisl dignissim sit amet. In vel enim a risus varius fringilla. Nulla eu consequat felis. Integer consequat turpis vitae dolor placerat lacinia. Aenean accumsan efficitur urna, a aliquet tortor consequat non. Fusce consectetur diam vitae pellentesque eleifend. Donec at tortor vitae lectus feugiat lobortis vitae ac lectus.',
      img: '',
    },
    {
      question: 'Question 2',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquam elit quis velit fermentum, eget tristique tortor hendrerit. Suspendisse sed aliquet sapien. Sed finibus consectetur ex, et tempor nisl dignissim sit amet. In vel enim a risus varius fringilla. Nulla eu consequat felis. Integer consequat turpis vitae dolor placerat lacinia. Aenean accumsan efficitur urna, a aliquet tortor consequat non. Fusce consectetur diam vitae pellentesque eleifend. Donec at tortor vitae lectus feugiat lobortis vitae ac lectus.',
      img: ''
    },
    {
      question: 'Question 3',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquam elit quis velit fermentum, eget tristique tortor hendrerit. Suspendisse sed aliquet sapien. Sed finibus consectetur ex, et tempor nisl dignissim sit amet. In vel enim a risus varius fringilla. Nulla eu consequat felis. Integer consequat turpis vitae dolor placerat lacinia. Aenean accumsan efficitur urna, a aliquet tortor consequat non. Fusce consectetur diam vitae pellentesque eleifend. Donec at tortor vitae lectus feugiat lobortis vitae ac lectus.',
      img: ''
    },
    {
      question: 'Question 4',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquam elit quis velit fermentum, eget tristique tortor hendrerit. Suspendisse sed aliquet sapien. Sed finibus consectetur ex, et tempor nisl dignissim sit amet. In vel enim a risus varius fringilla. Nulla eu consequat felis. Integer consequat turpis vitae dolor placerat lacinia. Aenean accumsan efficitur urna, a aliquet tortor consequat non. Fusce consectetur diam vitae pellentesque eleifend. Donec at tortor vitae lectus feugiat lobortis vitae ac lectus.',
      img: ''
    },
    {
      question: 'Question 5',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquam elit quis velit fermentum, eget tristique tortor hendrerit. Suspendisse sed aliquet sapien. Sed finibus consectetur ex, et tempor nisl dignissim sit amet. In vel enim a risus varius fringilla. Nulla eu consequat felis. Integer consequat turpis vitae dolor placerat lacinia. Aenean accumsan efficitur urna, a aliquet tortor consequat non. Fusce consectetur diam vitae pellentesque eleifend. Donec at tortor vitae lectus feugiat lobortis vitae ac lectus.',
      img: '',
    }
  ];
  